/* tslint:disable */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-checkbox',
  template: `
      <div class="form-check">
          <input class="form-check-input" type="checkbox"
                 [class.is-invalid]="showError"
                 [indeterminate]="IsIndeterminate"
                 [formControl]="formControl"
                 [formlyAttributes]="field">
          <label class="form-check-label" [for]="id">
              {{ to.label }}
              <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
          </label>
      </div>
  `,
})
export class FormlyFieldCheckbox extends FieldType {
  to: any;
  key: string;
    get IsIndeterminate(): boolean {
        return this.to.indeterminate && (this.model || {})[this.key] === undefined
    }
}
