import {Component, ElementRef, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'formly-field-html-block',
    encapsulation: ViewEncapsulation.None,
    template: `

    `,
})
export class FormlyFieldHtmlBlock extends FieldType implements OnInit {

    constructor(
        private el: ElementRef, private renderer: Renderer2
    ) {
        super();
    }

    ngOnInit(): void {
        const element = this.el.nativeElement;
        this.renderer.setProperty(element, 'innerHTML', this.field.templateOptions['html']);
    }


}
