import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {BrowserOptions} from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import {DashboardService} from "portal/pages/dashboard/dashboard.service";

export const SENTRY_SETTINGS = new InjectionToken<BrowserOptions>('SENTRY_SETTINGS');
export const RELEASE = new InjectionToken<string>('RELEASE');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor(
        @Inject(SENTRY_SETTINGS) sentrySettings: BrowserOptions,
        @Inject(RELEASE) release: string,
        private ds: DashboardService
    ) {
        if (!!sentrySettings && !window['Cypress']) {
            this.ds.userInfo$.subscribe(userInfo => Sentry.setUser({
                id: userInfo.id,
                email: userInfo.email,
                name: userInfo.name,
            }));
            Sentry.init({
                release,
                ...sentrySettings,
                replaysOnErrorSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                integrations: [
                    Sentry.replayIntegration({
                        // Additional SDK configuration goes in here, for example:
                        maskAllText: true,
                        blockAllMedia: true,
                    }),
                ]
            });
        }
    }

    handleError(error) {
        Sentry.captureException(error.error || error.message || error.originalError || error);
        throw error;
    }
}
