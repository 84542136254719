import { ConfigOption } from '@ngx-formly/core';
import { TemplateAddons } from './run/addon';
import { FormlyFieldSectionNameBlock } from './types/section-name-block';
import {
    FormlyFieldCheckbox,
    FormlyFieldInput,
    FormlyFieldMultiCheckbox,
    FormlyFieldRadio,
    FormlyFieldSelect, FormlyFieldHelpBlock,
    FormlyFieldTextArea,
} from './types/types';
import { FormlyWrapperAddons, FormlyWrapperFormField } from './wrappers/wrappers';
import {FormlyFieldHtmlBlock} from "shared/modules/forms/formly-bootstrap/types/html-block";

export const FIELD_TYPE_COMPONENTS = [
    // types
    FormlyFieldInput,
    FormlyFieldCheckbox,
    FormlyFieldRadio,
    FormlyFieldSelect,
    FormlyFieldHelpBlock,
    FormlyFieldHtmlBlock,
    FormlyFieldTextArea,
    FormlyFieldMultiCheckbox,

    // wrappers
    FormlyWrapperAddons,
    FormlyWrapperFormField,
];

export const BOOTSTRAP_FORMLY_CONFIG: ConfigOption = {
    types: [
        {
            name: 'input',
            component: FormlyFieldInput,
            wrappers: ['form-field'],
        },
        {
            name: 'checkbox',
            component: FormlyFieldCheckbox,
            wrappers: ['form-field'],
            defaultOptions: {
                defaultValue: false,
                templateOptions: {
                    indeterminate: true,
                    hideLabel: true,
                },
            },
        },
        {
            name: 'radio',
            component: FormlyFieldRadio,
            wrappers: ['form-field'],
            defaultOptions: {
                templateOptions: {
                    options: [],
                    hideLabel: true,
                },
            },
        },
        {
            name: 'select',
            component: FormlyFieldSelect,
            wrappers: ['form-field'],
            defaultOptions: {
                templateOptions: {
                    options: [],
                },
            },
        },
        {
            name: 'help-block',
            component: FormlyFieldHelpBlock,
        },
        {
            name: 'html-block',
            component: FormlyFieldHtmlBlock,
        },
        {
            name: 'textarea',
            component: FormlyFieldTextArea,
            wrappers: ['form-field'],
            defaultOptions: {
                templateOptions: {
                    cols: 1,
                    rows: 1,
                },
            },
        },
        {
            name: 'section-name-block',
            component: FormlyFieldSectionNameBlock,
        },
        {
            name: 'multicheckbox',
            component: FormlyFieldMultiCheckbox,
            wrappers: ['form-field'],
            defaultOptions: {
                templateOptions: {
                    options: [],
                },
            },
        },
    ],
    wrappers: [
        { name: 'addons', component: FormlyWrapperAddons },
        { name: 'form-field', component: FormlyWrapperFormField },
    ],
};
