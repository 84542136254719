import {Component} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
/** TODO
 * Make not searchable by default
 */

/*tslint:disable component-class-suffix component-selector*/
@Component({
    selector: 'formly-field-help-block',
    template: `
        <div class="form-text" [innerHTML]="field.templateOptions['html']"></div>
    `,
})
export class FormlyFieldHelpBlock extends FieldType {

    constructor() {
        super();
    }

}
